<template>
  <v-stepper v-model="step">
    <!-- Step 1 -->
    <v-stepper-header>
      <v-stepper-step :complete="step > 1" step="1">Business Details</v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="step > 2" step="2">Contact Information</v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step :complete="step > 3" step="3">Menu & Location</v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step step="4">Password</v-stepper-step>
    </v-stepper-header>

    <!-- Step 1 Content -->
    <v-stepper-items class="mb-10">
      <v-stepper-content step="1" class="step">
        <v-form>
          <v-text-field label="Name" v-model="business.name"></v-text-field>
          <v-text-field label="Store" v-model="business.store_name"></v-text-field>
          <v-text-field label="User" v-model="business.user.name" disabled></v-text-field>
          <v-btn class="bg-primary text-light" style="margin-top:250px" @click="step = 2">Next</v-btn>
        </v-form>
      </v-stepper-content>

      <!-- Step 2 Content -->
      <v-stepper-content step="2" class="step">
        <v-form>
          <v-text-field label="Business Email" v-model="business.user.email"></v-text-field>
          <v-text-field label="Phone" v-model="business.phone"></v-text-field>
          <v-text-field label="Food" v-model="business.food_type.name" disabled></v-text-field>
          <v-row class="d-flex justify-space-between align-end" style="height: 100%;">
            <v-col class="d-flex justify-start">
              <v-btn class="bg-primary text-light" @click="step = 1" style="margin-top:250px">Back</v-btn>
            </v-col>
            <v-col class="d-flex justify-end">
              <v-btn class="bg-primary text-light" @click="step = 3" style="margin-top:250px">Next</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-stepper-content>

      <!-- Step 3 Content -->
      <v-stepper-content step="3" class="step">
        <v-form>
          <v-text-field label="Address" v-model="business.address"></v-text-field>
          <v-text-field label="lat" v-model="business.long"></v-text-field>
          <v-text-field label="long" v-model="business.lat"></v-text-field>

          <v-text-field label="City" v-model="business.city"></v-text-field>
          <v-text-field label="State" v-model="business.state"></v-text-field>
          <v-text-field label="Postal Code" v-model="business.postal_code"></v-text-field>
          <v-text-field label="Country" v-model="business.country.name" disabled></v-text-field>
          <v-textarea label="Menu Details" v-model="business.menu_details" rows="5"></v-textarea>
          <v-row class="d-flex justify-space-between align-end" style="height: 100%;">
            <v-col class="d-flex justify-start">
              <v-btn class="bg-primary text-light" @click="step = 2" style="margin-top:40px">Back</v-btn>
            </v-col>
            <v-col class="d-flex justify-end">
              <v-btn class="bg-primary text-light" @click="step = 4" style="margin-top:40px">Next</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-stepper-content>

      <!-- Step 4 Content -->
      <v-stepper-content step="4" class="step">
        <v-form>
          <v-text-field label="New Password" type="password" v-model="pwd.password"></v-text-field>
          <v-text-field label="Confirm Password" type="password" v-model="pwd.confirmation_password"></v-text-field>
          <v-row class="d-flex justify-space-between align-end" style="height: 100%;">
            <v-col class="d-flex justify-start">
              <v-btn class="bg-primary text-light" @click="step = 3" style="margin-top:350px">Back</v-btn>
            </v-col>
            <v-col class="d-flex justify-end">
              <v-btn class="bg-primary text-light" @click="submitForm" :loading="loading" style="margin-top:350px">
                Save
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

export default {
  name: "BusinessEdit",
  data() {
    return {
      step: 1,
      loading: false, // Loading state for the submit button
      business: {
        name: "",
        lat:"",
        long:"",
        store_name: "",
        user: { name: "", email: "" },
        phone: "",
        food_type: { name: "" },
        address: "",
        city: "",
        state: "",
        postal_code: "",
        country: { name: "" },
        menu_details: ""
      },
      pwd: {
        password: "",
        confirmation_password: ""
      }
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "All Businesses", route: "businesses" },
      { title: "Edit Business", route: "admin/businesses/edit" }
    ]);
    if (!this.$store.state.businesses) {
      this.$router.push({ name: "businesses" });
    }
    this.business = this.$store.state.businesses;
  },
  methods: {
    submitForm() {
  this.loading = true; // Set loading to true when submitting

  // Array to hold promises for API calls
  const promises = [];

  // Update business details
  const businessPromise = ApiService.post("admin/update-business", this.business)
    .then(({ data }) => {
      if (data == 1) {
        Swal.fire("Confirmation!", "Business Updated Successfully", "success");
      } else if (data == 0) {
        Swal.fire("Error!", "Something went wrong", "warning");
      } else {
        Swal.fire("Error!", data, "error");
      }
    })
    .catch(({ response }) => {
      Swal.fire("Error!", "Failed to update business", "error");
    });

  promises.push(businessPromise);

  // Update password if new password is provided
  if (this.pwd.password && this.pwd.confirmation_password) {
    const passwordFormData = {
      id: this.business.user_id,
      password: this.pwd.password,
      confirmation_password: this.pwd.confirmation_password
    };

    const passwordPromise = ApiService.post("admin/update-business-password", passwordFormData)
      .then(({ data }) => {
        if (data.status == "Success") {
          Swal.fire("Confirmation!", "Password Updated Successfully", "success");
        } else {
          Swal.fire("Error!", data, "error");
        }
      })
      .catch(({ response }) => {
        Swal.fire("Error!", "Failed to update password", "error");
      });

    promises.push(passwordPromise);
  }

  // Use Promise.all to wait for all promises to resolve
  Promise.all(promises)
    .then(() => {
      // All API calls completed
      this.loading = false; // Reset loading state
      this.$router.push({ name: "businesses" }); // Redirect to businesses page
    })
    .catch((error) => {
      console.error("Error during API calls:", error);
      this.loading = false; // Reset loading state in case of error
    });
}

  }
};
</script>

<style scoped>
.v-text-field--filled .v-input__control {
  background-color: white;
  border: 1px solid #9B92DD;
}

.v-text-field--filled input {
  color: #9B92DD;
}

.v-btn--primary,
.bg-primary text-light {
  background-color: #9b92dd;
  color: white;
}

.v-btn--primary:hover,
.bg-primary text-light:hover {
  background-color: #8a82cc;
}
.step {
  min-height: 500px;
}
</style>
